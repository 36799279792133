import React from 'react';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import { ServiceImage } from '@tradesorg-components/component-library-waas-templates.template-3-service-image';
import Markdown from 'markdown-to-jsx';
import {highlightedServicesMap} from '@assets/david/services';

const Services = ({ data }) => {
    const { slogan, heading, services } = data;

    return (
        <div id="services" className={`servicesComponentWrapper justify-center items-center`}>
            <div
                className="servicesImgWrapper px-5 md-1:px-10 xl:px-0 pt-50px pb-50px md-1:py-20 md-1:col-span-2 xl:col-span-1 relative
            xl:ml-98px xl:mr-98px xl:pt-200px border-b"
            >
                <SectionHeadingComponent
                    sloganText={slogan}
                    heading={<Markdown>{heading}</Markdown>}
                    centered={true}
                    className={{
                        slogan: 'xs:text-sm',
                        heading: 'xs:font-24px lg:font-30px mt-19px mb-30px md-1:mb-10 xl:mb-42px',
                    }}
                />
                <div
                    className={`serviceList grid grid-cols-1 md-1:grid-cols-2 2xl:grid-cols-3 gap-x-20px gap-y-20px mx-auto lg:max-w-2xl 2xl:max-w-6xl 3xl:max-w-none`}
                >
                    {services?.map(({ name, description, photo = [] }, index) => {
                        const serviceImg = highlightedServicesMap[name] || highlightedServicesMap['default']
                        return (
                            <ServiceImage
                                key={index}
                                title={name}
                                content={description}
                                img={!!photo?.length ? photo?.[0].url : serviceImg}
                                // btnText="CTA Button"
                                classNames={{
                                    wrapper: 'mx-auto',
                                    imgIcon: 'mt-69px mb-21px',
                                    imgWrapper: 'rounded-4px',
                                    contentWrapper: 'rounded-4px flex flex-col justify-center',
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Services;
