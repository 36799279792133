import React from 'react';
import { Template3BusinessFeature } from '@tradesorg-components/component-library-waas-templates.template-3-business-feature';
import './index.scss';
import BusinessFeatures from '@assets/david/business-features.jpg';

const AboutUs = ({ data }) => {
    const { title, businessFeatures: fetchedBusinessFeatures, description, image } = data;

    const businessFeatures = fetchedBusinessFeatures.map((feature) => {
        return { name: feature.title };
    });

    return (
        <div
            className={`servicesImgWrapper px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1
         relative xl:ml-98px xl:mr-98px xl:pt-200px border-b`}
        >
            <div className="relative max-w-lg lg:max-w-7xl mx-auto lg:flex ">
                <div className="lg:w-1/2">
                    <img
                        src={BusinessFeatures}
                        alt="Business Amenities"
                        className="lg:w-1/2 lg:ml-auto lg:mr-16"
                    />
                </div>

                <div className="lg:w-1/2">
                    {/* <p className="max-w-lg leading-loose opacity-50">It's difficult to find examples of lorem ipsum in use before Letraset made it popular as a dummy text in the 1960s, although McClintock says he remembers coming across the lorem ipsum passage in a book of old metal type samples. So far he hasn't relocated where he once saw the passage.</p> */}
                    <div className="font-extrabold leading-7 mt-29px mb-29px xl:mt-0 font-24px">
                        Our <span className="text-blue-500">business</span> features:
                    </div>
                    <Template3BusinessFeature
                        className={{
                            wrapper: 'flex w-full relative flex-wrap',
                            icon: 'mr-19px w-19px h-16px',
                            item: 'w-full lg:w-1/2 mb-20px leading-loose flex items-center',
                        }}
                        iconColor={'#3287eb'}
                        items={businessFeatures}
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
