import React from 'react';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import { Progress } from '@tradesorg-components/component-library-waas-templates.template-3-progress';
import Markdown from 'markdown-to-jsx';
import './index.scss';
import ourProcessImage from '@assets/david/our-process.jpg';

const OurProgress = ({ data }) => {
    const { text, heading, processes, image } = data;

    const imageHandler = (index) => {
        switch (index % 1) {
            case 0:
                return ourProcessImage;
        }
    };
    const ablud = processes?.map(({ stepNumber, stepTitle, stepDescription }, index) => {
        return (
            <div className={'mb-5'} style={{ minHeight: '100px' }}>
                <div className={'float-left flex bg-blue-100 w-50px h-50px rounded-md items-center justify-center mr-3'}>
                    <span className={'font-bold font-18px sm:font-20px text-sm text-blue-500'}>
                        {index < 9 ? `0${index + 1}` : `${index + 1}`}
                    </span>
                </div>
                <Markdown>{stepDescription ? stepDescription : ''}</Markdown>
            </div>
        );
    });

    return (
        <div id="our-process" className={`ProgressComponentWrapper justify-center items-center`}>
            <div
                className="servicesImgWrapper px-5 md-1:px-10 xl:px-0 py-10 md-1:py-20 md-1:col-span-2 xl:col-span-1 relative
            xl:ml-98px xl:mr-98px xl:pt-200px border-b xl:pb-200px"
            >
                <SectionHeadingComponent
                    sloganText={text}
                    heading={<Markdown>{heading}</Markdown>}
                    centered={true}
                    className={{
                        heading: 'mt-15px lg:mt-19px xl:mb-10px xs:font-24px md-1:font-30px',
                        slogan: 'xs:font-14px md-1:font-16px',
                    }}
                />

                {processes?.length > 0 && (
                    <div className="list-progress">
                        <div className="mt-40px lg:mt-50px">
                            <Progress
                                title={null}
                                content={<div className={'mb-5'}>{ablud}</div>}
                                imageUrl={imageHandler(0)}
                                badgeText=""
                                className={{
                                    rightWrapper: ``,
                                    contentWrapper: `flex flex-row mx-auto xl:mx-0 md-1:pl-53px xl:pl-0 2xl:pl-53px 3xl:pl-0 lg:w-full`,
                                    wrapper: `max-w-5xl mx-auto`,
                                    badge: `xs:mt-0`,
                                    displayLine: ``,
                                    title: ``,
                                    img: `lg:h-397px xs:max-w-527px md-1:absolute md-1:w-1/2 m-auto`,
                                    content: `leading-8`,
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OurProgress;
