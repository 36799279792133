import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import './index.scss';
import FaqItemList from './faq-item-list';
import { Button } from '@tradesorg-components/component-library-waas-templates.base-button';
import { InputTemplate } from '@tradesorg-components/component-library-waas-templates.base-input-template';
import { Link } from 'gatsby';

const FAQS = ({ data }) => {
    const { slogan, heading, faqs } = data;

    const [question, setQuestion] = useState('');

    return (
        <div
            className={`FaqComponentWrapper justify-center items-center bg-gray-50 py-60px xl:py-150px px-20px`}
        >
            <div className="max-w-500px xl:max-w-814px mx-auto">
                <div className=" bg-white px-20px pt-44px pb-51px xl:px-80px xl:pb-80px xl:pt-71px">
                    <SectionHeadingComponent
                        sloganText={slogan}
                        heading={ReactHtmlParser(heading)}
                        centered={true}
                        className={{
                            heading:
                                'mt-19px xs:font-24px md-1:font-30px md-1:mb-10 xl:mb-42px text-center mb-35px md-1:mb-0',
                            slogan: 'xs:font-14px md-1:font-16px',
                        }}
                    />

                    <InputTemplate
                        autoComplete={'off'}
                        classNames={{
                            wrapper: 'faq-search sm:px-10px xs:mb-10px sm:mb-20px',
                            input: 'border-0 xs:px-0 sm:px-10px',
                            rightComponent: 'xs:pr-0 sm:pr-3',
                        }}
                        rounded={true}
                        name="search"
                        id="search"
                        placeholder="Enter your question..."
                        onChange={(e) => {
                            setQuestion(e.target.value);
                        }}
                        value={question}
                    />

                    <FaqItemList keyword={question} items={faqs} />
                </div>
                <div className="text-center mt-40px xl:mt-60px w-full md-1:max-w-273px mx-auto  ">
                    <Button style={{ width: '100%' }} template={3} variant="secondary">
                        <Link
                            to={'/faq'}
                            className="text-sm not-italic font-normal tracking-normal text-center leading-5 py-8px"
                        >
                            Read all FAQs
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FAQS;
