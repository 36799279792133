export const  requestQuote = async(form, to = null) => {
  const headers = {
      'x-api-key' : process.env.GATSBY_SEND_EMAIL_LAMBDA_API_KEY,
  }

  const quoteReceiver = to ? to : process.env.GATSBY_STRAPI_USER_EMAIL;
  const url = process.env.GATSBY_SEND_EMAIL_URL
  const {name,phone_number,email} = form;
  const body = 
    {
        "to" : `${quoteReceiver}`,
        "subject" : "Quote request",
        "text" : `${name} ${phone_number} ${email} requested quote`
    
  }

  const response = await fetch(url,{
      method : 'POST',
      body : JSON.stringify(body),
      headers
  })

  return response.json()
}