import Layout from '@components/commons/template-5/layout';
// import Guarantees from '@containers/template-5/homepage/guarantees';
import BusinessFeatures from '@containers/template-5/homepage/business-features';
import Faq from '@containers/template-5/homepage/faq';
import Guarantees from '@containers/template-5/homepage/guarantees';
import Intro from '@containers/template-5/homepage/intro';
import OurProgress from '@containers/template-5/homepage/our-process';
import Review from '@containers/template-5/homepage/review';
import Services from '@containers/template-5/homepage/services';
// import PhotoGallery from '@containers/template-5/homepage/photo-gallery';
import SocialLinks from '@containers/template-5/homepage/social-links';
// import OurTeam from '@containers/template-5/homepage/our-team'
import TradeCategory from '@containers/template-5/homepage/trade-category';
import data from '@data/template-5/homepage.json';
import { graphql } from 'gatsby';
import React from 'react';

const Homepage = ({
    data: {
        AbstractionDataStore: { getWebsite, getUser, getBusiness },
        DomainRequisition: { getDomainRequisitionsByBusinessId: domain },
    },
}) => {
    const appSyncData = {
        business: {
            ...getBusiness,
            phone: getBusiness?.phones?.items?.length
                ? getBusiness?.phones?.items[0]?.phoneNumber
                : getUser?.phone,
            email: getBusiness?.emails?.items?.length
                ? getBusiness?.emails?.items[0]?.email
                : getUser?.email,
            displayName: getBusiness?.displayName,
            shortDescription: getBusiness?.shortDescription,
            radiusServed: getBusiness?.serviceArea,
            socialProfiles: getBusiness?.links?.items || [],
            businessFeatures: getWebsite?.highlightedFeatures?.items
                ? getWebsite?.highlightedFeatures?.items
                : [],
            highlightedServices: getWebsite?.highlightedServices?.items
                ? getWebsite?.highlightedServices?.items
                : [],
            feedbacks: getWebsite?.testimonials?.items ? getWebsite?.testimonials?.items : [],
            processes: getBusiness?.processSteps?.items ? getBusiness?.processSteps?.items : [],
        },
        website: {
            ...getWebsite,
        },
    };

    const { business, website } = appSyncData;
    const usp = { option: website.headline };
    const businessData = {
        ...business,
        address: {
            address: business.location?.streetAddress,
            longitude: business.location.longitude,
            latitude: business.location.latitude,
            singleLineAddress: business.location.singleLineAddress,
            state: business.location.state,
        },
    };
    const businessLayoutData = {
        logo: businessData?.logoImage ?? null,
        phone: businessData.phone,
        email: businessData.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        services: website?.highlightedServices?.items ? website?.highlightedServices?.items : [],
        ...businessData,
    };
    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription,
        domain: domain?.[0]?.domainName,
        logo: businessData?.logoImage?.url ?? null,
        previewImage: businessData?.coverImage?.url ?? null,
    };
    const introData = {
        usp,
        title: businessData.displayName,
        subTitle: businessData.shortDescription,
        image: businessData.coverImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        email: businessData.email
    };
    const contactUsData = {
        ...data.contactUs,
        radius: businessData.radiusServed,
        address: businessData.location,
        phone: businessData.phone,
        email: businessData.email,
        singleLineAddress: businessData.location?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
    };
    const servicesData = {
        heading: data.services.title,
        services: businessData.highlightedServices,
    };
    const tradeCategoryData = {
        heading: data.tradeCategory.heading,
        text: '',
        categories: businessData?.professions?.items?.map(({ profession }) => ({
            professionLabel: profession.professionLabel,
        })),
    };
    const guaranteesData = {
        heading: data.guarantees.heading,
        text: '',
        guaranteesData: website?.guaranteeDescriptions?.items
            ? website?.guaranteeDescriptions?.items
            : [],
    };

    const ourProcessData = {
        heading: data.ourProcess.heading,
        text: businessData?.ourProcess?.caption,
        processes: businessData?.processes ? businessData.processes : [],
    };
    const aboutUsData = {
        title: businessData.shortDescription,
        description: businessData.longDescription,
        businessFeatures: businessData.businessFeatures,
        qualifications: businessData.qualifications.items,
    };
    const ourTeamData = {
        ...data.ourTeam,
        team: businessData?.teams ? businessData.teams : [],
    };
    const reviewsData = {
        ...data.reviews,
        feedbacks: website?.testimonials?.items ? website.testimonials.items : [],
    };
    const faqData = {
        ...data.faq,
        faqs: website?.faqs?.items ? website.faqs.items : [],
    };
    const subscribeData = {
        ...data.subscribe,
    };

    return (
        <Layout businessData={businessLayoutData} seoData={seoData} faqs={faqData}>
            <Intro data={introData} />
            {!!servicesData?.services?.length && <Services data={servicesData} />}
            {!!tradeCategoryData?.categories?.length && <TradeCategory data={tradeCategoryData} />}
            {!!ourProcessData.processes.length && <OurProgress data={ourProcessData} />}
            {!!website?.guarantee?.caption && <Guarantees data={guaranteesData} />}
            <BusinessFeatures data={aboutUsData} />
            {!!reviewsData.feedbacks.length && <Review data={reviewsData} />}
            {!!faqData.faqs.length && <Faq data={faqData} />}
            {!website.hideMyLocation && <SocialLinks data={contactUsData} />}
        </Layout>
    );
};

export const query = graphql`
    query ($userId: ID!, $businessId: ID!, $websiteId: ID!, $businessIdString: String!, $tradeProId: ID!) {
        DomainRequisition {
            getDomainRequisitionsByBusinessId(businessId: $businessIdString) {
                id
                domainName
                domainStatus
            }
        }
        AbstractionDataStore {
            getWebsite(id: $websiteId) {
                promotionVisibility
                hideMyLocation
                headline
                subHeadline
                feature
                promotion {
                    description
                    value
                }
                guarantee {
                    caption
                }
                faqs {
                    items {
                        question
                        answer
                    }
                }
                highlightedFeatures {
                    items {
                        text
                        title
                    }
                }
                highlightedServices {
                    items {
                        description
                        name
                    }
                }
                completedProjects {
                    items {
                        description
                        name
                    }
                }
                guaranteeDescriptions {
                    items {
                        title
                        description
                    }
                }
                testimonials {
                    items {
                        description
                        name
                    }
                }
            }
            getTradePro(id: $tradeProId) {
                photoId
                photo {
                    url
                    caption
                    longDescription
                }
            }
            getUser(id: $userId) {
                email
                phone
            }
            getBusiness(id: $businessId) {
                id
                displayName
                shortDescription
                serviceArea
                logoImage {
                    url
                    caption
                    longDescription
                }
                location {
                    longitude
                    latitude
                    singleLineAddress
                    state
                    streetAddress
                }
                processSteps {
                    items {
                        stepNumber
                        stepDescription
                        stepTitle
                    }
                }
                ourProcess {
                    caption
                }
                feedbacks {
                    items {
                        review
                        fullName
                    }
                }
                professions {
                    items {
                        profession {
                            professionLabel
                        }
                    }
                }
                links {
                    items {
                        url
                    }
                }
                emails {
                    items {
                        email
                    }
                }
                phones {
                    items {
                        phoneNumber
                    }
                }
                qualifications {
                    items {
                        title
                        caption
                    }
                }
            }
        }
    }
`

export default Homepage
