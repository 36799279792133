import Img1 from '@assets/images/about-us-template-2.png';
import ImageGuarantees from '@assets/david/guarantee.jpg';
import classNames from 'classnames';
import React from 'react';
import Slick from 'react-slick';

const serviceTileClasses = (inverted) =>
    classNames({
        inverted: inverted === true,
    });

export function CustomSlider({ title, subTitle, items, ...props }) {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    dotClass: 'dotClass',
    infinite: items.length > 2,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: '#ddd',
          borderRadius: '10px',
          padding: '10px'
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className="dot-item"></div>
    )
  }

  const classNames = {
    wrapper: `bg-gray-50  2xl:h-707px md-1:h-450px`,
    title: `mt-10 text-2xl font-semibold lg:font-30px line-height-13`,
    subTitle: `font-14px`,
    content: `mt-22px font-16px lg:my-6`,
    buttonWrapper: `w-full mt-5 hidden`,
    img: `max-w-7xl w-full h-96 md:h-full bg-no-repeat bg-cover md-1:absolute left-0 bottom-0 top-0 justify-center md-1:w-1/2 image`,
    fakeImage: `hidden md-1:block md-1:w-1/2`,
    rightWrapper: `flex max-w-7xl w-full mx-auto px-4 flex-wrap content`,
    contentWrapper: `w-full md-1:w-1/2 flex flex-col max-w-lg mx-auto md-1:px-25px text-center md-1:text-left h-444px`
  }

  return (
    <div className="flex relative">
      <div
        className={`w-full flex items-center flex-wrap justify-end flex-wrap service-tile ${serviceTileClasses(
          false
        )} ${classNames.wrapper}`}
      >
        <div
          className={`w-full lg:w-1/2 bg-no-repeat bg-cover lg:absolute left-0 bottom-0 top-0 justify-center image ${classNames?.img}`}
          style={{ backgroundImage: `url(${ImageGuarantees ?? Img1})` }}
        />
        <div className={`${classNames.rightWrapper}`}>
          <div className={`w-full lg:w-1/2 ${classNames.fakeImage}`} />
          <div className={`${classNames?.contentWrapper}`}>
          {title && (<span className={`${classNames?.title}`}>{title}</span>)}
            {subTitle && <p className={`${classNames?.content}`}>{subTitle}</p>}
            <div className="slider-wrapper" {...props}>
              <Slick {...settings}>{
                items.map(({ title, description }) => {
                  return (
                    <>
                      <h1 className={`${classNames?.title}`}>{title}</h1>
                      {description && <div className={`${classNames?.text}`}>{description}</div>}
                    </>
                  )
                })
              }</Slick>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
