import React from 'react';
import { Circle, GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { CategoryCardComponent } from '@tradesorg-components/component-library-waas-templates.template-3-category-card';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import iconFacebook from '@assets/icons/template-3/facebook.svg';
import iconInstagram from '@assets/icons/template-3/instagram.svg';
import iconPinterest from '@assets/icons/template-3/pinterest.svg';
import iconHouse from '@assets/icons/template-3/house.svg';
import iconLinkedin from '@assets/icons/template-3/linkedin.svg';
import iconHouzz from '@assets/icons/template-3/houzz.svg';
import { useWindowDimensions } from '@utils/customHooks';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';

const SocialLinks = ({ data }) => {
    const { phone, email, address, radius, socialProfiles } = data;
    const { width } = useWindowDimensions();

    const contacts = [
        {
            name: formatPhoneNumber(phone),
            type: 'phone',
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        // {
        //     name: address?.singleLineAddress,
        //
        //     icon: (
        //         <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             className="h-5 w-5 text-blue-500"
        //             viewBox="0 0 20 20"
        //             fill="currentColor"
        //         >
        //             <path
        //                 fillRule="evenodd"
        //                 d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        //                 clipRule="evenodd"
        //             />
        //         </svg>
        //     ),
        // },
        {
            name: email,
            type: 'email',
            icon: (
                <svg
                    xmlns="Ưhttp://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
            ),
        },
        // {
        //     'name': '9am - 9pm every day',
        //     'icon': <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
        //     <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
        //   </svg>
        // }
    ];

    const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('intagram')) {
            return iconInstagram;
        }
        if (key.includes('house')) {
            return iconHouse;
        }
        if (key.includes('houzz')) {
            return iconHouzz;
        }
        if (key.includes('pinterest')) {
            return iconPinterest;
        }

        return iconLinkedin;
    };

    const MyMapComponent = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap
                defaultZoom={10}
                defaultCenter={{
                    lat: parseFloat(address?.latitude),
                    lng: parseFloat(address?.longitude),
                }}
            >
                {props.isMarkerShown && (
                    <Marker
                        position={{
                            lat: parseFloat(address?.latitude),
                            lng: parseFloat(address?.longitude),
                        }}
                    />
                )}

                {/* INVESTIGATE RADIUS VALUE, HOW IT WORKS */}
                {/*<Circle*/}
                {/*    defaultCenter={{*/}
                {/*        lat: parseFloat(address?.latitude),*/}
                {/*        lng: parseFloat(address?.longitude),*/}
                {/*    }}*/}
                {/*    radius={parseInt(radius) * 100}*/}
                {/*    options={{*/}
                {/*        strokeColor: '#FF0000',*/}
                {/*        strokeOpacity: 0.8,*/}
                {/*        strokeWeight: 2,*/}
                {/*        fillColor: '#FF0000',*/}
                {/*        fillOpacity: 0.35,*/}
                {/*    }}*/}
                {/*/>*/}
            </GoogleMap>
        )),
    );

    return (
        <div className="socialLinkMapWrapper" id="contact">
            <div
                className={`socialComponentWrapper grid grid-cols-1 md-1:grid-cols-2 mt-120px mx-5 2xl:ml-98px 2xl:mr-98px `}
            >
                {!!socialProfiles?.length && (
                    <div className="socialLinkWrapper">
                        <SectionHeadingComponent
                            heading={<div>Follow us on social channels</div>}
                            half={width >= 1024 && true}
                            centered={width < 1024 && true}
                        />
                        <div className="grid grid-cols-1 lg:grid-cols-2 mt-53px gap-y-20px lg:gap-y-10">
                            {socialProfiles?.map((item, index) => {
                                return (
                                    <CategoryCardComponent
                                        key={index}
                                        heading={item.name}
                                        classNames={{
                                            wrapper: 'items-center',
                                            heading: 'text-base text-black font-medium font-18px',
                                            contentWrapper: 'pl-15px pr-0px  w-full',
                                        }}
                                        icon={
                                            <a href={item.url} target="_blank" rel="noopener">
                                                <img src={handleRenderIcon(item.url)} />
                                            </a>
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="contactUsWrapper mt-66px md-1:mt-0">
                    <SectionHeadingComponent
                        heading={<div>Contact Us</div>}
                        half={width >= 1024 && true}
                        centered={width < 1024 && true}
                    />
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-53px gap-y-20px lg:gap-y-10">
                        {contacts.map((item, index) => {
                            if (item.type) {
                                if (item.type === 'phone') {
                                    return (
                                        <a href={`tel:${item.name}`}>
                                            <CategoryCardComponent
                                                key={index}
                                                heading={item.name}
                                                classNames={{
                                                    wrapper: 'items-center',
                                                    heading:
                                                        'text-base text-black font-medium font-18px',
                                                    contentWrapper: 'pl-15px pr-0px w-full',
                                                }}
                                                icon={item.icon}
                                            />
                                        </a>
                                    );
                                } else {
                                    return (
                                        <a href={`mailto:${item.name}`}>
                                            <CategoryCardComponent
                                                key={index}
                                                heading={item.name}
                                                classNames={{
                                                    wrapper: 'items-center',
                                                    heading:
                                                        'text-base text-black font-medium font-18px',
                                                    contentWrapper: 'pl-15px pr-0px w-full break-all',
                                                }}
                                                icon={item.icon}
                                            />
                                        </a>
                                    );
                                }
                            } else {
                                return (
                                    <CategoryCardComponent
                                        key={index}
                                        heading={item.name}
                                        classNames={{
                                            wrapper: 'items-center',
                                            heading: 'text-base text-black font-medium font-18px',
                                            contentWrapper: 'pl-15px pr-0px w-full',
                                        }}
                                        icon={item.icon}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className="contactMapWrapper mt-74px lg:mt-100px">
                <MyMapComponent
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GATSBY_G_MAP}&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        </div>
    );
};

export default SocialLinks;
