import React from 'react';
import './index.scss';
import { CustomSlider } from '../../../../components/commons/template-5/cutom-slider';

const Guarantees = ({ data }) => {
    const { slogan, title, content } = data;
    return (
        <div
            className="introComponentWrapper justify-center items-center"
            style={{ background: '#f9f9fc' }}
        >
            <div className="relative">
                <CustomSlider
                    items={data.guaranteesData}
                    title={data.heading}
                    subTitle={data.text}
                />
            </div>
        </div>
    );
};

export default Guarantees;
