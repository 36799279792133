import hero01 from './hero-01.jpg'
import hero02 from './hero-02.jpg'
import hero03 from './hero-03.jpg'
import hero04 from './hero-04.jpg'
import hero05 from './hero-05.jpg'
import hero06 from './hero-06.jpg'
import hero07 from './hero-07.jpg'
import hero08 from './hero-08.jpg'
import hero09 from './hero-09.jpg'
import hero10 from './hero-10.jpg'


const heroImages = {
  hero01,
  hero02,
  hero03,
  hero04,
  hero05,
  hero06,
  hero07,
  hero08,
  hero09,
  hero10
}

export default heroImages
