import ConcreteDriveways from './concrete-driveways.jpg';
import ConcreteFinisher from './concrete-finisher.jpg';
import ConcretePatios from './concrete-patios.jpg';
import ConcreteStamped from './concrete-stamped.jpg';
import ConcreteStampedRepair from './concrete-stamped-repair.jpg';
import DeckAndPatioContractors from './deck-and-patio-contractors.jpg';
import FenceContractor from './fence-contractor.jpg';
import FlooringContractor from './flooring-contractor.jpg';
import Handyman from './handyman.jpg';

export const highlightedServicesMap = {
    'Concrete Driveways': ConcreteDriveways,
    'Concrete Patios': ConcretePatios,
    'Stamped Concrete': ConcreteStamped,
    'Stamped Concrete Repairs': ConcreteStampedRepair,
    'Deck and Patio Contractors': DeckAndPatioContractors,
    'Handyman': Handyman,
    'Fence Contractor': FenceContractor,
    'Concrete Finisher': ConcreteFinisher,
    'Flooring Contractor': FlooringContractor,
    'default': Handyman,
};
