import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CategoryCardComponent } from '@tradesorg-components/component-library-waas-templates.template-3-category-card';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import { useWindowDimensions } from '@utils/customHooks';

const TradeCategoryList = ({ data }) => {
    const { heading, categories } = data;
    const { width } = useWindowDimensions();
    return (
        <div
            className="tradeCategoriesWrapper px-5 md-1:px-10 xl:px-0 pt-36px md-1:py-20 md-1:col-span-2 xl:col-span-1 relative
        xl:pl-98px xl:pr-98px 3xl:pl-390px 3xl:pr-390px xl:pt-76px xl:pb-0px"
        >
            <SectionHeadingComponent
                heading={ReactHtmlParser(heading)}
                half={width > 1024 && true}
                centered={width <= 768 && true}
                className={{
                    heading: 'mt-19px mb-30px md-1:mb-10 xl:mb-42px',
                }}
            />
            <div
                className={`tradeCategoryList grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-20px gap-y-20px`}
            >
                {categories?.map(({ professionLabel }, index) => {
                    return (
                        <CategoryCardComponent
                            key={index}
                            heading={professionLabel}
                            classNames={{
                                wrapper: 'items-center',
                                heading: 'text-base text-black',
                                contentWrapper: 'pl-15px pr-0px  w-full',
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default TradeCategoryList;
